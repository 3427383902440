import React from 'react'
import Modal from '../components/Video/FirstScreen/Modal'

const ModalPage = ({data, location}) => {
    let langBase = "";
    let back = "Вернуться";
	let error = "Видео не доступно";

    if (location.pathname.match("/ua") && location.pathname.match("/ua").index === 0) {
        langBase = "/ua";
        back = "Повернутися"
		error = "Відео недоступне";
    }

    let backPath = `${langBase}/video/category/${data.allDirectusVideo.edges[0].node.category.slug}`;
    
    const newData = {
        title: data.allDirectusVideo.edges[0].node.name,
        videoLink: data.allDirectusVideo.edges[0].node.link,
    }
    
    return <Modal backPath={backPath} data={newData} back={back} errorText={error}/>
}

export const query = graphql`
    query($slug: String!) {
        allDirectusVideo (filter: {slug: {eq: $slug}}) {
            edges {
                node {
                    category {
                        slug
                    }
                    slug
                    title
                    link
                }
            }
        }
    }
`

export default ModalPage